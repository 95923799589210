import { memo, useCallback, useMemo } from 'react';
import { Grid, PaletteColor, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DateCalendar, DayCalendarSkeleton, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment-timezone';
import { useSafetyPredictionServiceGetDailyBerthsSafetyWorstWarnings } from '@/api/ui/queries';
import { BerthSafetyWarningSummary } from '@/api/ui/requests';
import { safetyPredictionTypeColor } from '@/features/color-utils';
import useOrganisation from '@/hooks/useOrganisation';
import { DATE_FORMAT } from '@/types';

type WarningDayProps = {
  day: Moment;
  value: number;
  color: PaletteColor | undefined;
};

const WarningDay = (
  props: PickersDayProps<Moment> & {
    daysWithWarnings: WarningDayProps[];
    maxDay: Moment;
    onDateSelected: (day: Moment) => void;
    theme: Theme;
  }
) => {
  const { theme, maxDay, daysWithWarnings = [], day, onDateSelected, ...other } = props;

  const isSelectedDayHasWarning = useCallback(
    (warningDay: WarningDayProps | undefined) => warningDay?.day && moment(warningDay.day).isSame(day, 'date'),
    [day]
  );
  const dayWithWarning = useMemo(() => daysWithWarnings.find(isSelectedDayHasWarning), [daysWithWarnings, isSelectedDayHasWarning]);
  const onWarningDaySelected = useCallback((d: Moment) => dayWithWarning && onDateSelected(d), [onDateSelected, dayWithWarning]);
  const backgroundColor = useMemo(() => {
    if (day.isAfter(maxDay)) return undefined;
    return dayWithWarning?.color ?? theme.palette.secondary;
  }, [day, maxDay, dayWithWarning, theme.palette.secondary]);
  const dateAsString = day.format(DATE_FORMAT);

  return (
    <PickersDay
      {...other}
      day={day}
      key={dateAsString}
      data-testid={dateAsString}
      disableHighlightToday={true}
      onDaySelect={onWarningDaySelected}
      sx={{
        fontWeight: 600,
        color: backgroundColor?.contrastText,
        bgcolor: backgroundColor?.main,
        '&:hover, &:focus': {
          bgcolor: backgroundColor?.main,
        },
      }}
    />
  );
};

type CalendarCommonProps = {
  warningFilter: (_: BerthSafetyWarningSummary) => boolean;
  onDateSelected: (_: Moment) => void;
};

const extractWorstWarnings = (theme: Theme, warnings: BerthSafetyWarningSummary[]) => {
  if (warnings.length === 0) return undefined;

  //find warning with the highest percentage
  const worstWarning = warnings.reduce((prev, current) => (prev.percentage > current.percentage ? prev : current));
  return {
    worstPercentage: worstWarning.percentage,
    worstIndicatorColor: safetyPredictionTypeColor(theme, worstWarning.safetyPredictionType, worstWarning.isCustomerDefinedType),
  };
};

const MonthlyWarningCalendar = ({
  organisationId,
  startDate,
  endDate,
  warningFilter,
  onDateSelected,
}: {
  organisationId: string;
  startDate: Moment;
  endDate: Moment;
} & CalendarCommonProps) => {
  const theme = useTheme();
  const start = startDate.format(DATE_FORMAT);
  const end = endDate.format(DATE_FORMAT);
  const { isLoading, data: dailyWarnings } = useSafetyPredictionServiceGetDailyBerthsSafetyWorstWarnings(
    {
      xSelectedOrganisationId: organisationId,
      startDate: start,
      endDate: end,
    },
    [organisationId, start, end],
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const daysWithWarnings = useMemo(
    () =>
      dailyWarnings
        ?.map(({ day, warnings }) => {
          if (warnings.length == 0) return null;

          const extractedWarning = extractWorstWarnings(theme, warnings.filter(warningFilter));

          return (
            extractedWarning && {
              day: moment(day),
              value: extractedWarning.worstPercentage,
              color: extractedWarning.worstIndicatorColor,
            }
          );
        })
        ?.filter(Boolean) ?? [],
    [dailyWarnings, warningFilter, theme]
  );

  const monthAsString = startDate.format('MMMM YYYY');

  return (
    <Grid item xs={6} sm={4} lg={3} display="grid" justifyItems={'center'} key={monthAsString}>
      <Typography variant="highlightSemiBold">{monthAsString}</Typography>
      <DateCalendar
        slots={{
          day: WarningDay,
        }}
        slotProps={{
          calendarHeader: { sx: { display: 'none' } },
          day: {
            onDateSelected,
            daysWithWarnings,
            theme,
          } as unknown,
        }}
        loading={isLoading}
        renderLoading={() => <DayCalendarSkeleton />}
        minDate={startDate}
        maxDate={endDate}
        disableFuture={true}
      />
    </Grid>
  );
};

type HistoricWarningCalendarProps = {
  selectedYear: number;
} & CalendarCommonProps;

function HistoricWarningCalendar({ selectedYear, warningFilter, onDateSelected }: HistoricWarningCalendarProps) {
  const { selectedOrganisationId } = useOrganisation();
  const monthlyCalendars = useMemo(() => {
    return moment
      .months()
      .filter((month) => {
        const distantMonth = moment().year(selectedYear).month(month);
        return distantMonth.isSameOrBefore(moment(), 'month');
      })
      .reverse()
      .map((month) => {
        const distantMonth = moment().year(selectedYear).month(month);
        const startOfMonth = moment(distantMonth).startOf('month').local(true);
        const endOfMonth = moment(distantMonth).endOf('month').local(true);

        return (
          <MonthlyWarningCalendar
            organisationId={selectedOrganisationId}
            startDate={startOfMonth}
            endDate={endOfMonth}
            warningFilter={warningFilter}
            key={`${month}-${selectedYear}`}
            onDateSelected={onDateSelected}
          />
        );
      });
  }, [selectedYear, selectedOrganisationId, warningFilter, onDateSelected]);

  return (
    <Grid container spacing={0.4}>
      {monthlyCalendars}
    </Grid>
  );
}

export default memo(HistoricWarningCalendar);
