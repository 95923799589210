import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { TodayRounded, ZoomOutMap } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Box, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers';
import { useSearchParams } from 'react-router-dom';
import { Map } from 'leaflet';
import moment from 'moment';
import { useSafetyPredictionServiceGetBerths } from '@/api/ui/queries';
import { BerthDmaCaseSafetyWarning, BerthSafetyResponse, SafetyPredictionType, VesselType } from '@/api/ui/requests';
import activeIcon from '@/assets/svg/marker-checked.svg';
import icon from '@/assets/svg/marker.svg';
import { ReactComponent as SimulationView } from '@/assets/svg/simulation.svg';
import AutoComplete from '@/components/common/AutoComplete/AutoComplete';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import NoScrollBox from '@/components/layout/NoScrollBox/NoScrollBox';
import { ControlProps, MapMarkerProps } from '@/components/maps/Map/types';
import useToBerthSafetyNavigate from '@/features/SafetyPrediction/BerthDashboard/useToBerthSafetyNavigate';
import {
  AboveLowerLimitBerthIcon,
  AboveUpperCustomerLimitBerthIcon,
  AboveUpperLimitBerthIcon,
  BelowLowerLimitBerthIcon,
  DisabledBerthIcon,
} from '@/features/SafetyPrediction/Icons';
import { getAdjustedTimestampForPrediction } from '@/features/SafetyPrediction/Overview/common';
import {
  OVERVIEW_MAP_GRAPH_DAYS_IN_ADVANCE,
  OVERVIEW_MAP_PREDICTION_DAYS_IN_ADVANCE,
  SAFETY_PREDICTION_TIMESTAMP_QUERY_FACTOR,
} from '@/features/SafetyPrediction/Overview/types';
import useMapParams from '@/hooks/useMapParams';
import useOrganisation from '@/hooks/useOrganisation';
import usePageTitle from '@/hooks/usePageTitle';
import useSearchParamState from '@/hooks/useSearchParamState';
import { Analytics } from '@/services/analytics';
import { SafetyPredictionTriggerStrings } from '@/types';
import classes from './index.module.css';

const SimulationMap = lazy(() => import('@/components/maps/Map/BerthMap'));

const berthThresholdIcon = (safetyWarning?: BerthDmaCaseSafetyWarning, enabled: boolean = true) => {
  let icon = <BelowLowerLimitBerthIcon />;

  if (!enabled) {
    icon = <DisabledBerthIcon />;
  } else if (!safetyWarning) {
    icon = <BelowLowerLimitBerthIcon />;
  } else if (!safetyWarning.type) {
    icon = <DisabledBerthIcon />;
  } else if (safetyWarning.type === SafetyPredictionType.ABOVE_LOWER_LIMIT) {
    icon = <AboveLowerLimitBerthIcon />;
  } else if (safetyWarning.type === SafetyPredictionType.ABOVE_UPPER_LIMIT && safetyWarning.customerDefinedType) {
    icon = <AboveUpperCustomerLimitBerthIcon />;
  } else if (safetyWarning.type === SafetyPredictionType.ABOVE_UPPER_LIMIT && !safetyWarning.customerDefinedType) {
    icon = <AboveUpperLimitBerthIcon />;
  } else if (safetyWarning.type === SafetyPredictionType.BELOW_LOWER_LIMIT) {
    icon = <BelowLowerLimitBerthIcon />;
  }

  return icon;
};

function popup(berthName: string, warnings: BerthDmaCaseSafetyWarning[], enabled = true) {
  return (
    <Box sx={{ minWidth: '330px', maxWidth: '330px', padding: 0 }}>
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <Grid direction="row" container>
            <Grid item sx={{ maxWidth: 50, paddingRight: 5 }} xs={1}>
              {berthThresholdIcon(warnings.length > 0 ? warnings[0] : undefined, enabled)}
            </Grid>
            <Grid item xs={10} alignContent={'center'}>
              <Typography variant={'header'} className={markerClass(warnings)}>
                <b>{berthName}</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!enabled && (
          <Grid item paddingTop={1}>
            <Typography variant="header">
              No safety predictions available! <br />
              Berth does not actively receive metocean data.
            </Typography>
          </Grid>
        )}
        {warnings.length == 0 && enabled && (
          <Grid item paddingTop={1}>
            <Typography variant="header">No warnings found</Typography>
          </Grid>
        )}
        {warnings.length > 0 && maxWarning(warnings)}
      </Grid>
    </Box>
  );
}

function maxWarning(warnings: BerthDmaCaseSafetyWarning[]) {
  const maxWarning = warnings?.reduce(function (a, b) {
    return a.percentage > b.percentage || (a.percentage == b.percentage && a.timestamp < b.timestamp) ? a : b;
  });

  const timestamp = moment(maxWarning.forecast.timestamp).local();
  const today = moment().local().isSame(timestamp, 'day');
  const indication = today ? `at ${timestamp.format('HH:mm')}` : timestamp.fromNow();
  return (
    <Grid key={`${maxWarning.berthId}-${maxWarning.dmaCaseId}`} item marginTop={1}>
      <Typography variant="header">Governing {VesselType[maxWarning.vesselType]?.toLowerCase()} vessel:</Typography>
      <Grid item paddingLeft={2}>
        <Typography variant="header" sx={{ display: 'block' }}>
          {maxWarning.vesselClass}
        </Typography>
      </Grid>
      <Typography variant="header" sx={{ display: 'block' }}>
        &ge;&nbsp;{maxWarning.percentage}% of MBL {indication}
      </Typography>
      {SafetyPredictionTriggerStrings[maxWarning.trigger]?.name && (
        <Typography variant="header" sx={{ display: 'block' }}>
          Caused by: &nbsp;{SafetyPredictionTriggerStrings[maxWarning.trigger]?.name}
        </Typography>
      )}
    </Grid>
  );
}

const markerClass = (warnings: BerthDmaCaseSafetyWarning[]) => {
  let markerClass = classes.inactiveMarker;

  if (warnings.length == 0) {
    markerClass = classes.belowLowerMarker;
  } else if (warnings.filter((warning) => warning.type === SafetyPredictionType.ABOVE_UPPER_LIMIT && !warning.customerDefinedType).length > 0) {
    markerClass = classes.exceededUpperMarker;
  } else if (warnings.filter((warning) => warning.type === SafetyPredictionType.ABOVE_UPPER_LIMIT && warning.customerDefinedType).length > 0) {
    markerClass = classes.exceededUpperCustomerMarker;
  } else if (warnings.filter((warning) => warning.type === SafetyPredictionType.ABOVE_LOWER_LIMIT).length > 0) {
    markerClass = classes.exceededLowerMarker;
  }

  return markerClass;
};

function SafetyPredictionSimulationView() {
  usePageTitle('Simulation - Map', <SimulationView width={32} key={'Simulation - Map'} />);
  const { selectedOrganisationId } = useOrganisation();
  const { navigateToBerthSafety } = useToBerthSafetyNavigate();
  const [startDate, setStartDate] = useSearchParamState('date', moment().subtract(1, 'hours').startOf('hour').toISOString(true));
  const [berthsOptions, setBerthsOptions] = useState<BerthSafetyResponse[]>([]);
  const [berth, setBerth] = useState<BerthSafetyResponse | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    isError,
    error,
    isLoading,
    data: berths,
  } = useSafetyPredictionServiceGetBerths(
    {
      xSelectedOrganisationId: selectedOrganisationId,
      startTime: startDate,
      numberOfDays: OVERVIEW_MAP_PREDICTION_DAYS_IN_ADVANCE,
    },
    [selectedOrganisationId, startDate],
    {
      refetchOnWindowFocus: false,
      refetchInterval: 3600000,
    }
  );

  const { zoomLevel, center, setMapParams } = useMapParams();

  useEffect(() => {
    Analytics.track('Simulation', {});
  }, []);

  useEffect(() => {
    if (berths) {
      setBerthsOptions(
        berths
          .sort((berth1, berth2) => berth1.name.localeCompare(berth2.name))
          .filter((berth) => berth.enabled)
          .filter((berth, index, array) => array.findIndex((elem) => berth.name === elem.name) === index)
      );
    }
  }, [berths]);
  useEffect(() => {
    if (berth) {
      searchParams.set('lat', berth.location.latitude.toString());
      searchParams.set('lng', berth.location.longitude.toString());
      searchParams.set('zoom', '16');
      searchParams.set('berthId', berth.berthId);
      setSearchParams(searchParams);
    }
  }, [berth, searchParams, setSearchParams]);

  const navigateToBerth = useCallback(
    (berth: BerthSafetyResponse) => {
      if (!berth) return;

      const { warnings, timezones, berthId } = berth;
      const warning =
        warnings?.length < 2 ? berth.warnings[0] : berth.warnings.reduce((prev, current) => (prev.percentage > current.percentage ? prev : current));

      const requestTimestamp = getAdjustedTimestampForPrediction(
        moment(startDate),
        OVERVIEW_MAP_GRAPH_DAYS_IN_ADVANCE * SAFETY_PREDICTION_TIMESTAMP_QUERY_FACTOR
      ).tz(timezones?.at(0));

      navigateToBerthSafety({
        berthId: berthId,
        loadingCondition: warning?.loadingCondition,
        trigger: warning?.trigger,
        dmaCaseId: warning?.dmaCaseId,
        isOperationalVessel: false,
        vesselDisposition: warning?.vesselDisposition,
        requestTimestamp,
        days: OVERVIEW_MAP_GRAPH_DAYS_IN_ADVANCE,
      });
    },
    [navigateToBerthSafety, startDate]
  );

  const resetZoom = useCallback(() => {
    searchParams.delete('lat');
    searchParams.delete('lng');
    searchParams.delete('zoom');
    searchParams.delete('berthId');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const isActive = useCallback(
    (berthId: string) => {
      return berth ? berth.berthId === berthId : searchParams.get('berthId') === berthId;
    },
    [berth, searchParams]
  );

  const markers: MapMarkerProps[] = useMemo(() => {
    if (!berths) return [];

    return berths.map((berth) => {
      return {
        latitude: berth.location.latitude,
        longitude: berth.location.longitude,
        markerIcon: {
          icon: icon,
          className: berth.enabled ? markerClass(berth.warnings) : classes.inactiveMarker,
          iconSize: 24,
        },
        activeMarkerIcon: {
          icon: activeIcon,
          className: berth.enabled ? markerClass(berth.warnings) : classes.inactiveMarker,
          iconSize: 32,
        },
        popup: {
          content: popup(berth.name, berth.warnings, berth.enabled),
          closeButton: false,
        },
        active: isActive(berth.berthId),
        onMouseOver: (event) => {
          event.target.openPopup();
        },
        onMouseOut: (event) => {
          event.target.closePopup();
        },
        onClick: (_, map: Map) => {
          setMapParams(map);
          navigateToBerth(berth);
        },
      };
    });
  }, [berths, isActive, navigateToBerth, setMapParams]);

  const controls: ControlProps[] = useMemo(
    () => [
      {
        position: 'topleft',
        children: (
          <Stack
            direction={'row'}
            sx={{
              bgcolor: 'background.default',
              width: { xs: '48vw', sm: '42vw', md: '40vw', lg: '30vw' },
              border: '2px solid #999999',
              borderRadius: 2,
              padding: 1,
            }}
          >
            <Box sx={{ width: { xs: '48vw', sm: '42vw', md: '40vw', lg: '30vw' } }}>
              <AutoComplete<BerthSafetyResponse>
                options={berthsOptions}
                value={berthsOptions?.find((b) => b.berthId === searchParams.get('berthId') ?? null) || null}
                setValue={setBerth}
                displayFunction={(berth: BerthSafetyResponse) => berth.name}
                startAdornment={<SearchIcon />}
                placeHolder={'Search for a berth'}
                equalsFunction={(berth1: BerthSafetyResponse, berth2: BerthSafetyResponse) => berth1.berthId === berth2.berthId}
                optionsLimit={50}
              />
            </Box>
            <Box>
              <IconButton title={'Reset zoom'} onClick={() => resetZoom()}>
                <ZoomOutMap fontSize={'large'} />
              </IconButton>
            </Box>
          </Stack>
        ),
      },
      {
        position: 'topright',
        children: (
          <Box
            sx={{
              bgcolor: 'white',
              opacity: 100,
              minWidth: 150,
              border: '2px solid #999999',
              borderRadius: 2,
              padding: 1,
            }}
          >
            <IconButton
              onClick={() => setStartDate(moment(startDate).subtract(1, 'days').toISOString())}
              disabled={moment(startDate).diff(moment(), 'days') < -14}
            >
              <Tooltip title={'Simulation overview of the previous day'}>
                <ArrowLeftIcon fontSize={'medium'} />
              </Tooltip>
            </IconButton>

            <IconButton
              onClick={() => {
                searchParams.delete('date');
                setSearchParams(searchParams);
              }}
              disabled={moment(startDate).isSame(moment(), 'day')}
            >
              <Tooltip title={'Go back to today'}>
                <TodayRounded fontSize={'medium'} />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => setStartDate(moment(startDate).add(1, 'days').toISOString())}
              disabled={moment(startDate).diff(moment(), 'days') > 1}
            >
              <Tooltip title={'Simulation overview of the next day'}>
                <ArrowRightIcon fontSize={'medium'} />
              </Tooltip>
            </IconButton>
          </Box>
        ),
      },
      {
        position: 'bottomleft',
        children: (
          <Box sx={{ backgroundColor: 'background.default', padding: 1, border: '1px solid #999999', borderRadius: 2 }}>
            <Typography variant="header" sx={{ textAlign: 'center' }}>
              Period: {moment(startDate).format('DD MMM HH:mm')} -{' '}
              {moment(startDate).add(OVERVIEW_MAP_PREDICTION_DAYS_IN_ADVANCE, 'days').format('DD MMM HH:mm')}
            </Typography>
          </Box>
        ),
      },
    ],
    [berthsOptions, resetZoom, searchParams, setSearchParams, setStartDate, startDate]
  );

  if (berths && berths.length > 0) {
    return (
      <NoScrollBox component="div">
        <Suspense fallback={<LoadingIndicator message={'Loading berth locations and safety predictions...'} />}>
          <SimulationMap mapMarkers={markers} controls={controls} showZoomControl={true} clustering={true} zoomLevel={zoomLevel} center={center} />
        </Suspense>
      </NoScrollBox>
    );
  }

  if (isError) {
    return (
      <Box sx={{ marginX: 'auto', marginY: 10, width: '50vw' }}>
        <ApiErrorAlert error={error} />
      </Box>
    );
  }

  if (!isLoading && markers.length === 0) {
    return (
      <Box sx={{ marginX: 'auto', marginY: 10, width: '50vw' }}>
        <Alert severity="warning" color={'warning'}>
          No berths found.
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ marginX: 'auto', marginY: 20, width: '50vw' }}>
      <LoadingIndicator message={'Retrieving berth information for your organisation...'} />
    </Box>
  );
}

export default SafetyPredictionSimulationView;
