import { createSearchParams, useNavigate } from 'react-router-dom';
import { Moment } from 'moment-timezone';
import type { LoadingCondition, SafetyPredictionTrigger, VesselDisposition } from '@/api/ui/requests';
import { SafetyPredictionTriggerStrings } from '@/types';

type BerthSafetyNavigationProps = {
  berthId: string;
  isOperationalVessel: boolean;
  dmaCaseId?: string;
  loadingCondition?: LoadingCondition;
  vesselDisposition?: VesselDisposition;
  trigger?: SafetyPredictionTrigger;
  requestTimestamp?: Moment;
  days?: number;
  vesselMmsi?: string;
};

const searchParamsBuilder = (searchParams: URLSearchParams, name: string, value?: string) => {
  value && searchParams.set(name, value);
};

function useToBerthSafetyNavigate() {
  const navigate = useNavigate();

  const navigateToBerthSafety = ({
    berthId,
    loadingCondition,
    trigger,
    dmaCaseId,
    isOperationalVessel,
    vesselDisposition,
    requestTimestamp,
    days,
    vesselMmsi,
  }: BerthSafetyNavigationProps) => {
    const warningTrigger = SafetyPredictionTriggerStrings[trigger];

    const searchParams = createSearchParams();
    searchParamsBuilder(searchParams, 'mmsi', vesselMmsi);
    searchParamsBuilder(searchParams, 'dmaCaseId', dmaCaseId);
    searchParamsBuilder(searchParams, 'loadingCondition', loadingCondition);
    searchParamsBuilder(searchParams, 'date', requestTimestamp?.toISOString());
    searchParamsBuilder(searchParams, 'disposition', vesselDisposition);
    searchParamsBuilder(searchParams, 'graph', warningTrigger?.graphName);
    searchParamsBuilder(searchParams, 'motion', warningTrigger?.motion);
    searchParamsBuilder(searchParams, 'period', days?.toString());
    searchParamsBuilder(searchParams, 'tz', requestTimestamp?.tz());

    const basePath = `/safety-prediction/${isOperationalVessel ? 'operational' : 'simulation'}/berths/${berthId}`;
    navigate({
      pathname: basePath,
      search: searchParams.toString(),
    });
  };

  return {
    navigateToBerthSafety,
  };
}

export default useToBerthSafetyNavigate;
